<template>
  <b-modal
    :id="`advertiser-balance-transfer-modal`"
    :modal-class="'default-modal'"
    header-class="justify-content-between"
    :hide-footer="true"
    size="lg"
    @shown="shown"
    @hidden="hidden"
  >
    <template #modal-header="{ close }">
      <div class="text-center">
        <h2 class="highlighted-color1">
          <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Transferir saldo - {{ advertiser.name }}</h1>
        </h2>
      </div>
      <!--begin::Close-->
      <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close" @click="close()">
          <span class="svg-icon svg-icon-1">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
            </svg>
          </span>
      </div>
      <!--end::Close-->
    </template>
    <div>
      <AlertWraper
        :content="content"
        :type="content_type"
      />
      <div class="mt-4 text-center">
        <p>Por favor, escolha para qual anunciante você quer transferir.</p>
        <div class="mt-3">
          <SelectAdvertiserInput
            :company="advertiser.company"
            v-model="form.recipient_id"
          />
        </div>
        <div class="mt-3" v-if="step === 'amount'">
          <p>Agora, selecione o quanto você quer transferir para <strong>{{ recipient.name }}</strong>.</p>
          <p>
            <strong>Saldo de {{ advertiser.name }}</strong>: {{ advertiser.available_amount / 100 | formatMoney }}<br/>
            <strong>Saldo de {{ recipient.name }}</strong>: {{ recipient.available_amount / 100 | formatMoney }}
          </p>

          <div class="mt-3">
            <form class="default-form" @submit.prevent="">
              <Money
                class="form-control mb-3"
                v-model.trim="form.amount"
                v-bind="money"
              />

              <div class="mt-5">
                <Button
                  class="btn btn-primary"
                  @click="changeStep('confirm')"
                  :disabled="form.amount === 0 || form.amount * 100 > advertiser.available_amount"
                  >
                  Avançar
                </Button>
              </div>
            </form>
          </div>
        </div>
        <div class="mt-3" v-if="step === 'confirm'">
          <p>Você confirma que gostaria de transferir <strong>{{ form.amount | formatMoney }}</strong> do anunciante <strong>{{ advertiser.name }}</strong> para o anunciante <strong>{{ recipient.name }}</strong>?</p>
          <div class="mt-5 d-flex justify-content-center">
            <Button
              class="btn btn-primary"
              @click="submit"
              :loading="loader"
            >
              Transferir
            </Button>

            <Button
              class="btn btn-danger ms-4"
              @click="changeStep('amount')"
            >
              Voltar
            </Button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>

import NotificationService from '@/modules/notifications/services/notifications-service'
import AdvertisersService from '@/modules/advertisers/services/advertisers-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'
import { Money } from 'v-money'
import SelectAdvertiserInput from '@/components/advertisers/SelectAdvertiserInput'

export default {
  name: 'AdvertiserBalanceTransfersModal',
  props: ['advertiser'],
  components: {
    AlertWraper,
    Button,
    Money,
    SelectAdvertiserInput
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      form: {
        recipient_id: '',
        amount: 0
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      loader: '',
      recipient: '',
      step: 'init'
    }
  },
  created () {
  },
  watch: {
    'form.recipient_id' (newValue) {
      if (newValue) {
        this.getRecipientAdvertiser()
      } else {
        this.recipient = ''
      }
    }
  },
  methods: {
    changeStep (step) {
      this.step = step
    },
    /**
     * Get recipient advertiser
    */
    async getRecipientAdvertiser () {
      if (!this.form.recipient_id) return
      this.recipient = await AdvertisersService.getAdvertiser(this.form.recipient_id)
      this.step = 'amount'
    },
    /**
     * On hidden
    */
    hidden () {
      this.reset()
    },
    /**
     * On shown
    */
    shown () {
      this.reset()
    },
    /**
     * Load more pixels
     */
    async submit () {
      this.loader = true
      this.content = ''
      try {
        await AdvertisersService.transferAmount({
          advertiserId: this.advertiser.id,
          recipientId: this.form.recipient_id,
          amount: this.form.amount * 100
        })
        this.$emit('transfered', this.advertiser)
        this.$bvModal.hide('advertiser-balance-transfer-modal')
        const notification = {
          notification: {
            type: 'success',
            content: 'Saldo transferido com sucesso'
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    },
    /**
     * Reset
    */
    reset () {
      this.step = 'init'
      this.recipient = ''
      this.form.amount = 0
      this.form.recipient_id = ''
      this.content = ''
    }
  }
}
</script>
